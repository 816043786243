.checkmark {
    width: 40px;
    height: 40px;
    margin-top: 10px;
}

.checkmark path {
    stroke: #28a745;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    stroke-dasharray: -48;
    stroke-dashoffset: -48;
    animation: draw 1s forwards;
}

@keyframes draw {
    100% {
        stroke-dasharray: 48;
        stroke-dashoffset: 0;
    }
}