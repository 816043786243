@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import "overlayscrollbars/styles/overlayscrollbars.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f8fcfe linear-gradient(to bottom, #f8fcfe, #FFFFFF);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
}

.ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.50) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    z-index: 100;
}

.ReactModal__Content,
.ReactModal__Content--after-open {
    /* position: static !important; */
    inset: auto !important;
    border-radius: 10px !important;
    border: none !important;
    background-color: #fff;
    width: 450px;
    padding: 10px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ProseMirror-focused {
    outline: none;
}